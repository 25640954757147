$text-color: #101820;
$orange: #FFA600;

/* colours */

.color-white {
  background: #fff;

}

.color-black {
  background: $text-color;

}

.color-orange {
  background: #FF7E42;

}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(168.97deg, #FFA600 -5.76%, #FE5104 41.82%, #E82346 91.46%);
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.2)
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5)
}


* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  padding: 0;
  transition: background 1s ease;
  background: $text-color;
}

a:focus {
  outline: none;
}


/*Dots*/
.first-section-dots {
  position: absolute;
  right: 49%;
  bottom: 50px;
  display: inline-block;

  cursor: pointer;

  .dots {
    background-color: rgba(255, 255, 255, 0.5);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 12px #fff;
    -webkit-animation: separator-bounce 1.4s ease-in-out 0s infinite both;
    animation: separator-bounce 1.4s ease-in-out 0s infinite both;
  }

  .dot2 {
    margin-top: 18px;
    -webkit-animation-delay: .16s;
    animation-delay: .16s;
  }

  .dot3 {
    margin-top: 12px;
    -webkit-animation-delay: .32s;
    animation-delay: .32s;
  }
}

@keyframes separator-bounce {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  80% {
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }
  100% {
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }
}

/* Header and First Section */
.container-custom {
  position: relative;
  max-width: 1400px;
  padding-top: 20px;
  z-index: 99999;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .navbar-toggler-icon {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

nav.clone {
  position: fixed;
  background-color: #000;;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: 0.2s transform cubic-bezier(.3, .73, .3, .74);
}

body.down nav.clone {
  transform: translateY(0);
}

.nav-item a {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  padding: 1.2rem 0;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.3em;
    text-decoration-thickness: 2px;
  }
}

.nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.logo img {
  height: 36px;
}

.logo-nav img {
  height: 36px;
}

.first-bg {
  background: url("/assets/images/formas_bg.svg"), linear-gradient(168.97deg, #FFA600 -5.76%, #FE5104 41.82%, #E82346 91.46%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

.first-section-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  top: 0;
  right: 0;
  left: 0;

}


.first-section-text, .second-section-text {
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 21px;
  letter-spacing: 2px;
}

.first-section-title {
  font-size: 85px;
  font-weight: 700;
  color: #fff;
  line-height: 100px;

  span {
    height: 16px;
    width: 16px;
    background-color: $orange;
    border-radius: 50%;
    display: inline-block;
  }

}

.first-section-button {
  border: solid 2px $text-color;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
  color: #000;

  &:hover {
    background-color: $text-color;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
  }
}

/* Second Section */
.second-section-container {
  padding: 100px 0;
}

.second-section-text {
  color: #FFB800;
}

.second-section-title {
  font-size: 70px;
  font-weight: 700;
  color: #fff;
  line-height: 100px;
}

.second-section-paragraph {
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.5px;
}

.second-section-column {
  padding: 10px;
  box-shadow: 0px 10px 20px 5px rgba(16, 24, 32, 0.1);
  background-color: #fff;
}

.second-section-inner {
  padding: 30px;
  border: 1px solid #FE5104;
  height: 300px;
}

.second-section-column-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 25px;
  margin-bottom: 35px;
}

.second-section-column-text {
  font-size: 14px;
  line-height: 18px;
  color: #7C7C7C;
  margin-bottom: 35px;

  li {
    list-style: none;
    margin-left: -20px;
  }
}

.second-section-column-button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 14px;
}

/*Third section*/

.third-section-container {
  padding: 100px 0;
  background: url("/assets/images/formas_bg_2.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

@media screen and (max-width: 415px) {
  .third-section-container {
    background: url("/assets/images/formas_mobile.svg");
  }
}

.third-section-row-1 {
  margin-bottom: 60px;
  text-align: center;

  .first-section-title {
    font-size: 75px;
    font-weight: 700;
  }
}

// .third-section-row{
//   margin-bottom: 100px;
// }

.third-section-title-1 {
  font-size: 70px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 35px;
}

.third-section-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
}

.third-section-box {
  margin-bottom: 70px;

  h3 {
    text-align: right;
    color: $orange;
    font-size: 40px;
    font-weight: 500;
  }

  h4 {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .5px;
  }
}

.third-section-text-1 {
  font-size: 16px;
  color: #FF7E42;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 12px;
  letter-spacing: 2px;
}

.third-section-text {
  font-size: 16px;
  color: #7c7c7c;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 12px;
  letter-spacing: 2px;
}

.third-section-content {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.container-wide {
  max-width: 1440px
}

.img-fluid {
  position: relative !important;
}

.img-hover {
  transition: ease 0.5s;
  opacity: 0;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: #fff;
  color: $text-color;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 1px;
  padding-right: 20px;

  h6 {
    font-weight: 600;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
}

.img-hover-small {
  transition: ease 0.5s;
  opacity: 0;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: #fff;
  color: $text-color;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 1px;
  padding-right: 20px;

  h6 {
    font-weight: 600;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
}

.magic:hover .img-hover-small, .magic:hover .img-hover {
  opacity: 1;
  transform: translateY(-5px);
}


.third-section-gallery {
  margin-top: 60px;
  padding: 0;

  img {
    position: relative;
  }
}

.third-section-gallery-2 {
  margin-top: 30px;
  padding: 0;

  img {
    position: relative;
  }
}

#counter1, #counter2 {
  font-weight: bold;
}

#counter2 {
  color: #FE5104
}

/*FOURTH SECTION*/
.fourth-section-container {
  padding-top: 100px;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 40px;
    letter-spacing: 2px;
  }
}

.fourth-section-container2 {
  padding: 100px 0;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 40px;
    letter-spacing: 2px;
  }
}

/*FIFTH SECTION*/
.fifth-section-container {
  background-color: #FF6C29;
  padding: 100px 0;
}

.fifth-section-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 2px;
}

.fifth-section-title {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 25px;
}


.fifth-section-social {
  a:not(:last-child) {
    margin-right: 20px;
  }
}

.social-icon {
  border-radius: 50%;
  font-size: 20px;
  transition: all 0.3s;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.20);

  &:hover {
    background-color: #fff;
    color: $text-color;
    transition: all 0.3s;
  }

}

.facebook {
  padding: 9px 18px;
}

.linkedin, .insta {
  padding: 9px 15px;
}

/* SIXTH SECTION*/
.sixth-section-container {
  background-color: #fff;
  padding: 100px 0;
}

.sixth-section-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #FF6C29;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 2px;
}

.sixth-section-title {
  color: #101820;
  font-size: 36px;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 25px;
}

/*FOOTER*/
.footer {
  padding: 25px 0;
  background-color: #000;

  a:hover {
    color: #FFB800;
    text-decoration: none;
  }
}

.footer-text {
  color: white;
  margin-bottom: 0;
  font-size: 14px;
  display: inline-block;
}

.gpartners {
  width: 100px !important;
  display: inline-block;
}


/*MEDIA QUERIES*/
@media (min-width: 991px) and (max-width: 1200px) {
  .second-section-inner {
    height: 365px;
  }
  .second-section-column-title {
    font-size: 21px;
  }
}


@media screen and (max-width: 991px) {

  .container-custom {
    max-width: 100%;
  }

  .first-bg {
    background-size: auto;
    background-position: right;
    background-repeat: repeat-y;
  }

  .third-section-container {
    background-size: auto;
    background-position: left;
    background-repeat: repeat-y;
  }

  .navbar-collapse {
    background-color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-item:not(:last-child) {
    border-bottom: solid 1px #ffffff50;
  }

  .first-section-title {
    font-size: 65px;
    line-height: 80px;
    margin-top: 20px;
  }
  .first-section-button {
    margin-top: 20px;
  }

  .first-section-dots {
    bottom: 10px;
  }

  .second-section-title, .third-section-title-1 {
    line-height: 80px;
  }

  .second-section-column-title {
    font-size: 30px;
  }

  .form-input {
    width: 100% !important;
    font-size: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .third-section-row-1 {
    .first-section-title {
      font-size: 36px !important;
      line-height: 40px !important;
      margin-top: 20px;
    }
  }
  .first-section-title {
    font-size: 40px;
    line-height: 65px;
    margin-top: 20px;

    & span {
      width: 10px;
      height: 10px;
      margin-left: 2px;
    }
  }

  .first-section-button {
    margin-top: 20px;
  }

  .first-section-dots {
    bottom: 10px;
  }

  .second-section-title, .third-section-title-1 {
    font-size: 40px;
    line-height: 50px;
  }

  .third-section-box h3 {
    text-align: left;
  }

  .fifth-section-title, .sixth-section-title {
    font-size: 22px;
    line-height: 34px;
  }

  .fifth-section-email {
    font-size: 20px;
    line-height: 20px;
  }

  .form-input input, .form-input textarea {
    font-size: 18px !important;
  }

}

@media screen and (max-width: 578px) {
  .controls-top {
    display: none !important;
  }
}

@media screen and (max-width: 425px) {
  .first-section-title {
    font-size: 36px;
    line-height: 40px;
  }

  .second-section-inner {
    padding: 50px 40px;
  }

  .fifth-section-email-line {
    width: 100%;
  }

  .form-input .placeholder {
    font-size: 24px;
  }
}


/* CAROUSEL */
/* medium - display 2  */
.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.carousel-control-prev-icon {
  position: absolute;
  left: -20px;
  background-image: none;
}

.carousel-control-next-icon {
  position: absolute;
  right: -20px;
  background-image: none;
}

@media (max-width: 750px) {

  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(100%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-100%);
  }
}

@media (min-width: 751px) and (max-width: 768px) {

  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33%);
  }
}

/* large - display 3 */
@media (min-width: 769px) {

  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

@media (max-width: 750px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

@media (min-width: 751px) {
  .carousel-inner .carousel-item > div {
    display: block;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

.carousel-inner .carousel-item-right,
.carousel-inner .carousel-item-left {
  transform: translateX(0);
}

.carousel-item {
  img {
    background: white;
    border-radius: 0.5rem;
  }
}

@media (max-width: 590px) {
  .carousel-control-prev-icon {
    left: 0px
  }

  .carousel-control-next-icon {
    right: 0px;
  }
}

/*INPUTS*/

$highlight-color: black;
$placeholder-padding: 5px;
$placeholder-font-size: 28px;
$placeholder-font-size-small: 16px;
$placeholder-transition: 200ms ease-in-out;
$input-font-size: 28px;
$input-top: 15px;
$input-height: 80px;
$input-border-color: black;

.form-input {
  margin: 40px auto;
  position: relative;
  width: 60%;

  label {
    position: relative;
    width: 100%;
    min-height: $input-height + $input-top;
  }

  .placeholder {
    position: absolute;
    display: block;
    top: ($input-height / 2) + $placeholder-padding;
    z-index: 2;
    font-size: $placeholder-font-size;
    transition: all $placeholder-transition;
    width: 100%;
    cursor: text;
    text-align: center;
    color: black;
  }

  input, textarea {
    position: relative;
    top: $input-top;
    z-index: 1;
    width: 100%;
    font-size: $input-font-size;
    border: 0;
    border-bottom: 1px solid $input-border-color;
    transition: border-color $placeholder-transition;
    outline: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    background-color: transparent;
    color: #fff;
    text-align: center;
  }

  textarea {
    min-height: $input-height;
    padding: $placeholder-padding 0;
  }

  input {
    height: $input-height;
  }

  input:focus,
  input:valid,
  textarea:focus,
  textarea:valid {
    & + .placeholder {
      top: -10px;
      cursor: inherit;
      font-size: $placeholder-font-size-small;
      color: $highlight-color;
    }

    background-color: rgba(254, 254, 254, 0.1);
    border-bottom: 2px solid #fff;
  }
}

.form-button {
  background: #000000;
  border: 2px solid #101820;
  border-radius: 5px;
  color: #fff;
  padding: 12px 70px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
  transition: all 0.3s;

  &:hover {
    background-color: transparent;
    color: #000;
    transition: all 0.3s;
  }
}

@media screen and (max-width: 425px) {
  .form-input .placeholder {
    font-size: 20px;
  }
}

.swal2-styled.swal2-confirm {
  background-color: black !important;
}
